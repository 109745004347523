import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import Page from '../../../components/page/Page';
import {
  fetchGetAgmLatestAction,
  fetchGetAgmPreviousAction
} from '../../../services/api/agm/promiseActions';
import { fetchGetIssueIncorporatedAction } from '../../../services/api/issueIncorporated/promiseActions';
import LatestAgm from './main/LatestAgm';
import PersonInChargeInfo from './main/PersonInChargeInfo';
import PrevAgmList from './main/PrevAgmList';

const CorpDashboard = () => {
  const dispatch = useDispatch();
  const [latestAgm, setLatestAgm] = useState([]);
  const [preAgms, setPreAgms] = useState([]);
  const [person, setPerson] = useState();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchGetAgmLatestAction()).then((res) => {
      if (!res.error && res.data.length !== 0) {
        setLatestAgm(() => [...res.data]);
      }

      dispatch(fetchGetIssueIncorporatedAction()).then((res) => {
        if (!res.error) {
          setPerson(res.data.personInCharge);
        }

        dispatch(fetchGetAgmPreviousAction()).then((res) => {
          if (!res.error) {
            setPreAgms(() => [...res.data]);
          }
        });
      });
    });
  }, []);

  return (
    <Page>
      <ListWrapBlock>
        {latestAgm.length !== 0 && (
          <>
            <h4>최근 주주총회</h4>
            {latestAgm.map((agm, index) => {
              return <LatestAgm agm={agm} />;
            })}
          </>
        )}
        {/* <AgmStatus /> */}
        {preAgms.length !== 0 && (
          <>
            <h4>이전 주주총회 내역</h4>
            <PrevAgmList agms={preAgms} />
          </>
        )}
        {!!person && (
          <>
            <h4>
              업무 담당자 정보
              <Button
                type={ButtonTypes.Primary}
                size={ButtonSize.Table}
                onClick={() => history.push('/corp/info')}
              >
                수정
              </Button>
            </h4>
            <PersonInChargeInfo info={person} />
          </>
        )}
      </ListWrapBlock>
    </Page>
  );
};

export const ListWrapBlock = styled.div`
  // .list_wrap3
  max-width: 1360px;
  margin: 0 auto;
  & > h4 {
    // ist_ty07
    font-size: 20px;
    font-weight: bold;
    margin: 30px 0 12px;
    border-bottom: 0;
    // temp
    position: relative;
    & button {
      position: absolute;
      right: 0;
      margin-top: -6px;
    }
  }
`;

export default CorpDashboard;
