import React, { useEffect, useRef, useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import AuthRoute from '../../../AuthRoute';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import Gnb from '../../../components/page/Gnb';
import homeImg_1 from '../../../styles/images/common/sprite/home1_bg.png';
import homeImg_2 from '../../../styles/images/common/sprite/home2_bg.png';
import homeImg_3 from '../../../styles/images/common/sprite/home3_bg.png';
import homeImg_4 from '../../../styles/images/common/sprite/home4_bg.png';
import spCommonPng from '../../../styles/images/common/sprite/sp_common.png';
import MainPageFooter from './MainPageFooter';
import Pager from './Pager';
import { isMobile } from 'react-device-detect';
const SectionBlock = styled.div`
  // .fp_section
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
`;

const ContentBoxBlock = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  ${(props) => {
    switch (props.pageOrder) {
      case 0:
        return css`
          background: url(${homeImg_1}) no-repeat bottom center;
          background-color: #052850;
          & > ul {
            // #home1 .con-box > ul
            top: 52%;
            left: calc(50% - 600px / 2 + 100px);
          }
        `;
      case 1:
        return css`
          background: url(${homeImg_2}) no-repeat bottom right;
          background-color: #0a0809;
          & > ul {
            // #home2 .con-box > ul
            top: 50%;
            left: calc(50% - 600px / 2 + 100px);
          }
        `;
      case 2:
        return css`
          background: url(${homeImg_3}) no-repeat bottom right;
          background-color: #3d5d7b;
          & > ul {
            // #home3 .con-box > ul
            top: 52%;
            left: calc(50% - 900px / 2 + 210px);
          }
        `;
      case 3:
        return css`
          background: url(${homeImg_4}) no-repeat bottom left;
          background-color: #1d2d57;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            background-position: top;
          }
          & > ul {
            // #home4 .con-box > ul
            top: 52%;
            left: calc(33% - 630px / 2 + 60px);
          }
        `;
      default:
        return css`
          background: url(${homeImg_1}) no-repeat bottom center;
        `;
    }
  }}
  & > ul {
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      top: 30%;
      left: 0;
    }
  }

  background-size: cover;

  & > ul {
    // #fullpage .section .con-box > ul
    position: absolute;
    transform: translateY(-50%);
    display: inline-block;
    width: auto;
    & > li {
      // #fullpage .section .con-box > ul > li
      display: block;
      text-align: center;
      margin-top: 16px;
      & > p {
        font-size: 21px;
        color: rgb(255, 255, 255, 0.85);
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          font-size: 14px;
          text-align: left;
          word-break: keep-all;
          margin-left: 30px;
          line-height: 20px;
        }
        & > strong {
          // #fullpage .section .con-box > ul > li > p strong
          color: #fff;
        }
      }
    }
    & > li:first-of-type > p {
      // #fullpage .section .con-box > ul > li:first-of-type > p
      font-size: 52px;
      font-family: 'Roboto-Medium';
      font-weight: bold;
      color: rgb(255, 255, 255, 1);
      text-shadow: 1px 3px 3px rgb(0 0 0 / 50%);

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        font-size: 25px;
        line-height: 35px;
      }
    }
  }
`;

const ButtonBoxBlock = styled.div`
  position: absolute;
  left: calc(50% - 1360px / 2);
  bottom: 110px;
  display: block;
  width: 1360px;
  height: auto;
  text-align: right;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    right: 20px;
    left: unset;
    top: calc(50% + 200px / 2);
    display: block;
    width: auto;
  }
  & > button {
    // #fullpage .section .btn-box > button
    position: relative;
    display: inline-block;
    width: 320px;
    margin-left: 16px;
    height: 70px;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    border-radius: 35px;
    background-color: rgb(0, 0, 0, 0.42);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: auto;
      padding: 0 35px 0 20px;
      margin-right: 16px;
      height: 40px;
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      border-radius: 35px;
    }

    &::after {
      // #fullpage .section .btn-box > button::after
      content: '';
      position: absolute;
      bottom: 28px;
      right: 30px;
      width: 9px;
      height: 14px;
      background: url(${spCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -331px -1px;

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        bottom: 14px;
        right: 15px;
      }
    }
  }
`;

const MainPage = () => {
  const history = useHistory();
  const checkAnim = useRef(false);
  const pageNumber = useRef(0);
  const [page, setPage] = useState(0);
  const showFooter = useRef(false);
  const navigate = (url) => {
    history.push(url);
  };
  const handleWheelEvent = (e) => {
    if (!checkAnim.current) {
      checkAnim.current = true;
      handlePage(e.deltaY < 0);
    }
  };

  useEffect(() => {
    document.body.addEventListener('wheel', handleWheelEvent);
    return () => {
      document.body.removeEventListener('wheel', handleWheelEvent);
    };
  }, []);

  const handlePage = (isWheelUp) => {
    let scrollDiv = document.querySelector('#root > div > div');
    if (!scrollDiv) {
      return;
    }
    if (pageNumber.current === 3) {
      if (isWheelUp) {
        if (showFooter.current === true) {
          scrollDiv.style.transform = 'translate3d(0px, -300%, 0px)';
          showFooter.current = false;
        } else {
          scrollDiv.style.transform = 'translate3d(0px, -200%, 0px)';
          setPageNumber(2);
        }
      } else {
        scrollDiv.style.transform = 'translate3d(0px, -377%, 0px)';
        showFooter.current = true;
      }
      setTimeout(() => {
        checkAnim.current = false;
      }, 500);
    } else {
      if (isWheelUp) {
        const pageNum = pageNumber.current - 1 < 0 ? 0 : pageNumber.current - 1;
        setPageNumber(pageNum);
      } else {
        const pageNum = pageNumber.current + 1;
        setPageNumber(pageNum);
      }
      const value = `translate3d(0px, -${pageNumber.current * 100}%, 0px)`;
      scrollDiv.style.transform = value;
      setTimeout(() => {
        checkAnim.current = false;
      }, 500);
    }
  };

  const onChangePage = (page) => {
    let scrollDiv = document.querySelector('#root > div > div');
    if (!scrollDiv) {
      return;
    }
    const value = `translate3d(0px, -${page * 100}%, 0px)`;
    setPage(page);
    pageNumber.current = page;
    scrollDiv.style.transform = value;
    setTimeout(() => {
      checkAnim.current = false;
    }, 500);
  };

  const setPageNumber = (pageNum) => {
    setTimeout(() => {
      setPage(pageNum);
    }, 500);
    pageNumber.current = pageNum;
  };

  const getOS = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  };

  const installApp = () => {
    const os = getOS();
    if (os === 'iOS') {
      window.open(
        'https://apps.apple.com/kr/app/%EB%B8%94%EB%A1%9D%EC%B2%B4%EC%9D%B8-%EC%A0%84%EC%9E%90%EC%A3%BC%EC%B4%9D/id1594560487'
      );
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=com.blockchainagm.app'
      );
    }
  };

  return (
    <>
      <AuthRoute />
      <Gnb />
      <Pager currentPage={page} pageCnt={4} onClick={onChangePage} />
      <ReactPageScroller
        blockScrollUp={true}
        blockScrollDown={true}
        containerHeight={isMobile ? '100%' : '100vh'}
        animationTimer={500}
        renderAllPagesOnFirstRender={true}
        customPageNumber={pageNumber.current}
      >
        <SectionBlock>
          <ContentBoxBlock pageOrder={0}>
            <ul>
              <li>
                <p>
                  <span style={styles.fcCol01}>DID</span>와{' '}
                  <span style={styles.fcCol02}>블록체인</span>을 활용한
                  <br />
                  주주총회 전자투표
                </p>
              </li>
              <li>
                <p>
                  DID와 블록체인을 활용해 <strong>신뢰성</strong>과
                  <strong>안전성</strong>이 뛰어납니다.
                </p>
              </li>
            </ul>
          </ContentBoxBlock>
          <ButtonBoxBlock>
            {isMobile && (
              <button onClick={() => navigate('/about')}>서비스 소개</button>
            )}

            <button onClick={() => navigate('/login')}>로그인</button>
          </ButtonBoxBlock>
        </SectionBlock>
        <SectionBlock>
          <ContentBoxBlock pageOrder={1}>
            <ul>
              <li>
                <p>
                  <span style={styles.fcCol01}>전자투표</span>,
                  <span style={styles.fcCol02}> 전자위임서비스</span>의<br />
                  새로운 길을 열어갑니다!!
                </p>
              </li>
              <li>
                <p>
                  국내 공공부문에 도입된 전자투표 기반 사업을 수행해 온<br />
                  <strong>한국전자투표</strong>와 <strong>코스콤</strong>의
                  <strong> 블록체인 전자주총</strong>과 안전하게 주주총회에
                  참여하세요
                </p>
              </li>
            </ul>
          </ContentBoxBlock>
          <ButtonBoxBlock>
            <button onClick={() => history.push('/shareholder-guide')}>
              전자투표
            </button>
            <button onClick={() => history.push('/shareholder-guide')}>
              전자위임
            </button>
          </ButtonBoxBlock>
        </SectionBlock>
        <SectionBlock>
          <ContentBoxBlock pageOrder={2}>
            <ul>
              <li>
                <p>
                  <span style={styles.fcCol01}>주주</span>의
                  <span style={styles.fcCol02}>권리</span>!!
                  <br />
                  이제 포기하지 말고 당당히 행사하세요!
                </p>
              </li>
              <li>
                <p>
                  <strong>블록체인 전자주총</strong>과 함께
                  <strong> 언제 어디서나</strong> 주주의 권리를 행사하세요
                </p>
              </li>
            </ul>
          </ContentBoxBlock>
          <ButtonBoxBlock>
            {isMobile ? (
              <button onClick={() => installApp()}>앱 설치</button>
            ) : (
              <button onClick={() => navigate('/login')}>주주 로그인</button>
            )}
          </ButtonBoxBlock>
        </SectionBlock>
        <SectionBlock>
          <ContentBoxBlock pageOrder={3}>
            <ul>
              <li>
                <p>
                  누구보다 앞선 기업이라면!!
                  <br />
                  <span style={styles.fcCol01}>블록체인</span>
                  <span style={styles.fcCol02}>전자주총</span>
                </p>
              </li>
              <li>
                <p>
                  안전하고 편리하게 주주총회를 열어 <strong>주주</strong>의
                  <strong> 의견을 청취하세요</strong>
                </p>
              </li>
            </ul>
          </ContentBoxBlock>
          <ButtonBoxBlock>
            {isMobile ? (
              <button onClick={() => installApp()}>앱 설치</button>
            ) : (
              <button onClick={() => navigate('/login?type=corp')}>
                발행회사 로그인
              </button>
            )}
          </ButtonBoxBlock>
          <MainPageFooter />
        </SectionBlock>
      </ReactPageScroller>
    </>
  );
};

const styles = {
  fcCol01: {
    color: '#FF9330'
  },
  fcCol02: {
    color: '#0094FF'
  }
};

export default MainPage;
