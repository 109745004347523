import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import {
  idpwsign,
  SIGN_DATA_TYPE,
  SIGN_TYPE
} from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { PageInnerListWrap } from '../../../components/page/PageInner';
import { ModalContext } from '../../../context/ModalContext';
import useWindowSize from '../../../hooks/useWindowSize';
import { fetchGetAgmByAgmSeqnoAction } from '../../../services/api/agm/promiseActions';
import {
  fetchGetElectionsAgmBallotAction,
  fetchPostElectionsAgmVoteAction,
  fetchPutElectionsAgmCancelVoteAction
} from '../../../services/api/elections/promiseActions';
import { getCurrentDatetime } from '../../../utils/DateUtil';
import { EMPTY_FUNCTION } from '../../../utils/FunctionUtil';
import CertificateInfo from '../certification/main/CertificateInfo';
import { ExerciseMethod } from '../exerciseRecord/main/BoxInfo';
import {
  VOTE_BUTTON_SIZE,
  VOTE_BUTTON_TYPE
} from './main/components/DefaultButtonStyle';
import ExercisableStockAmount from './main/components/ExercisableStockAmount';
import { CancelBtn, OkBtn } from './main/components/popup/common/PopupButton';
import ConcentratePopup from './main/components/popup/ConcentratePopup';
import DiversePopup from './main/components/popup/DiversePopup';
import DiverseReasonPopup from './main/components/popup/DiverseReasonPopup';
import VotingExercisePopup from './main/components/popup/VotingExercisePopup';
import WithdrawDiversePopup from './main/components/popup/WithdrawDiversePopup';
import VoteAgendaBox from './main/components/VoteAgendaBox';
import VoteAgendaBoxTitle from './main/components/VoteAgendaBoxTitle';
import VoteBoxList from './main/components/VoteBoxList';
import VoteButton from './main/components/VoteButton';
import VoteButtonContainer from './main/components/VoteButtonContainer';
import VoteChildAgendaBox from './main/components/VoteChildAgendaBox';

export const VoteValueType = {
  Yes: 'ayes',
  No: 'noes',
  Absten: 'abstention'
};

const VotePage = ({ exerciseMethod }) => {
  const dispatch = useDispatch();
  const userSeqno = useSelector(
    (state) => state.common.user?.userSeqno || null
  );
  const [agmInfo, setAgmInfo] = useState();
  const [agendas, setAgendas] = useState(null);
  const [voteData, setVoteData] = useState([]);
  const [voters, setVoters] = useState([]);
  const [selectedVoters, setSelectedVoters] = useState([]);
  const [initialData, setInitialData] = useState();
  const [useDiverse, setUseDiverse] = useState(false);
  const [stockholders] = useState([]);
  const [cleanData, setCleanData] = useState();
  const [dataCleanTime] = useState();
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const [electronicProxyName, setElectronicProxyName] = useState(null);
  const history = useHistory();
  // 모바일
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  const match = useRouteMatch();
  const agmSeqno = match.params.agmSeqno;

  useEffect(() => {
    dispatch(fetchGetAgmByAgmSeqnoAction(agmSeqno))
      .then((res) => {
        setAgmInfo(res.data);

        if (
          exerciseMethod === ExerciseMethod.PROXY &&
          res.data.solicitor &&
          res.data.solicitor.voteProxyMap.length > 0
        ) {
          setElectronicProxyName(res.data.solicitor.voteProxyMap[0]);
        }
      })
      .then((res) => {
        // 투표 화면 데이터 조회
        dispatch(fetchGetElectionsAgmBallotAction(agmSeqno))
          .then((res) => {
            setAgendas(res.data.agendas);
            setVoters(res.data.electionVoters);
            if (res.data.electionVoters.length === 1) {
              setUseDiverse(res.data.electionVoters[0].diversity);
            }

            if (res.data.electionVoters.length === 1) {
              setSelectedVoters([res.data.electionVoters[0]]);
            }
            makeDefaultBallotData(res.data);
          })
          .catch((e) => {}); // console.log(e)signData);
      });
  }, [dataCleanTime]);

  useEffect(() => {
    // 불통일 신청 여부 체크
    setUseDiverse(
      selectedVoters.find((voter) => Object.keys(voter).includes('diversity'))
    );
    console.log(selectedVoters);
  }, [selectedVoters]);

  const selectedVoterHandler = (arr) => {
    /*
    if (arr.length > 1 && arr.length > selectedVoters.length) {
      if (voters[arr[arr.length - 1]]?.participated) {
        console.log('이미 투표를 행사한 주주와 함께 지정할 수 없습니다');
        setSelectedVoters(selectedVoters.splice(-1, 1));
        return;
      }
    }*/
    setSelectedVoters(voters.filter((voter, idx) => arr.includes(idx)));
  };
  const clearData = (isClear = true) => {
    if (isClear) {
      setVoteData(cleanData);
      //setDataCleanTime(Date.now());
    } else {
      setVoteData(initialData);
    }
    setUseDiverse(false);
  };

  const makeDefaultBallotData = ({ agm, agendas, electionVoters, key }) => {
    const agendaBallot = agendas.map((agenda) => {
      if (agenda.childs.length === 0) {
        return {
          agendaSeqno: agenda.agendaSeqno,
          ayes: 0,
          noes: 0,
          abstention: 0
        };
      } else {
        return {
          agendaSeqno: agenda.agendaSeqno,
          childs: agenda.childs.map((child) => ({
            agendaSeqno: child.agendaSeqno,
            ayes: 0,
            noes: 0,
            abstention: 0
          }))
        };
      }
    });

    let clean = electionVoters.map((voter) => {
      console.log('voter');
      console.log(voter);
      return {
        ballot: {
          timeStamp: new Date().getTime(),
          shareholderSeqno: voter.stockholderSeqno,
          values: agendaBallot
        },
        //signData: 'test',
        diversityExercise: null,
        electronicProxyName: null
      };
    });

    let votes = electionVoters.map((voter) => {
      const exercises = voter.exercises;
      return {
        ballot: {
          timeStamp: new Date().getTime(),
          shareholderSeqno: voter.stockholderSeqno,
          values: setExercises(agendaBallot, exercises)
        },
        //signData: 'test',
        diversityExercise: voter.diversity,
        electronicProxyName: voter.electronicProxyName
      };
    });
    setCleanData(clean);
    setVoteData(votes);
    setInitialData(votes);
  };

  // 행사 내역 세팅
  const setExercises = (agendaBallot, exercises) => {
    return agendaBallot.map((agenda) => {
      if (!Object.keys(agenda).includes('childs')) {
        let data = null;
        if (exercises) {
          data = exercises.find(
            (item) => item.agendaSeqno === agenda.agendaSeqno
          );
        } else {
          return agenda;
        }
        if (data) {
          return {
            agendaSeqno: agenda.agendaSeqno,
            ayes: data.yesStockQuantity,
            noes: data.noStockQuantity,
            abstention: data.absentStockQuantity
          };
        } else {
          return agenda;
        }
      } else {
        const newChilds = agenda.childs.map((child) => {
          let data = null;
          if (exercises) {
            data = exercises.find(
              (item) => item.agendaSeqno === child.agendaSeqno
            );
          } else {
            return child;
          }

          if (data) {
            return {
              agendaSeqno: child.agendaSeqno,
              ayes: data.yesStockQuantity,
              noes: data.noStockQuantity,
              abstention: data.absentStockQuantity
            };
          } else {
            return child;
          }
        });
        return {
          agendaSeqno: agenda.agendaSeqno,
          childs: newChilds
        };
      }
    });
  };

  const isAbstenAgenda = (agenda) => {
    const data = getStockData(agenda);
    if (data) {
      if (data.childs) {
        return data.childs[0].abstention > 0;
      } else {
        return data && data.abstention > 0;
      }
    }
  };

  const onConcentrateAbsten = (agenda) => {
    console.log('onConcentrateAbsten');
    //console.log(voteDataArr);
    const voteDataArr = [];
    for (let i = 0; i < selectedVoters.length; i++) {
      agenda.childs.map((child, idx) => {
        const newData = {
          agenda: child,
          voteValues: {
            [VoteValueType.Absten]:
              idx === 0
                ? Number(
                    getVoterExrcsAmount(selectedVoters[i], agenda.agendaSeqno)
                  )
                : 0,
            agendaSeqno: child.agendaSeqno,
            shareholderSeqno: selectedVoters[i].stockholderSeqno
          }
        };
        voteDataArr.push(newData);
        return newData;
      });
    }

    console.log('absten voteDataArr');
    console.log(voteDataArr);

    setVoteData(
      (oldData) =>
        oldData.map((data) => {
          return {
            ...data,
            ballot: {
              ...data.ballot,
              values: data.ballot.values.map((value) => {
                if (value.childs) {
                  return {
                    ...value,
                    childs: value?.childs.map((child) => {
                      const tmpData = voteDataArr.find(
                        (item) =>
                          data.ballot.shareholderSeqno ===
                            item.voteValues.shareholderSeqno &&
                          child.agendaSeqno === item.voteValues.agendaSeqno
                      );
                      if (tmpData) {
                        return {
                          agendaSeqno: tmpData.voteValues.agendaSeqno,
                          [VoteValueType.Yes]: 0,
                          [VoteValueType.No]: 0,
                          [VoteValueType.Absten]:
                            tmpData.voteValues[VoteValueType.Absten]
                        };
                      } else {
                        return child;
                      }
                    })
                  };
                } else {
                  return value;
                }
              })
            }
          };
        })
      //
    );
  };

  const onConcentrateVote = (voteDataArr) => {
    console.log('onConcentrateVote');
    console.log(voteDataArr);

    setVoteData(
      (oldData) =>
        oldData.map((data) => {
          return {
            ...data,
            ballot: {
              ...data.ballot,
              values: data.ballot.values.map((value) => {
                if (value.childs) {
                  return {
                    ...value,
                    childs: value?.childs.map((child) => {
                      const tmpData = voteDataArr.find(
                        (item) =>
                          data.ballot.shareholderSeqno ===
                            item.voteValues.shareholderSeqno &&
                          child.agendaSeqno === item.voteValues.agendaSeqno
                      );
                      if (tmpData) {
                        return {
                          agendaSeqno: tmpData.voteValues.agendaSeqno,
                          [VoteValueType.Yes]:
                            tmpData.voteValues[VoteValueType.Yes],
                          [VoteValueType.No]: 0,
                          [VoteValueType.Absten]: 0
                        };
                      } else {
                        return child;
                      }
                    })
                  };
                } else {
                  return value;
                }
              })
            }
          };
        })
      //
    );
  };

  const onVote = (agenda, voteValues, isAbsten = false) => {
    const initValue = { ayes: 0, noes: 0, abstention: 0 };
    // eslint-disable-next-line array-callback-return
    selectedVoters.map((voter) => {
      setVoteData((oldData) =>
        oldData.map((data) => {
          if (data.ballot.shareholderSeqno === voter.stockholderSeqno) {
            return {
              ...data,
              ballot: {
                ...data.ballot,
                values: data.ballot.values.map((value) => {
                  if (
                    !Object.keys(value).includes('childs') ||
                    value.childs.length === 0
                  ) {
                    if (value.agendaSeqno === agenda.agendaSeqno) {
                      if (isAbsten) {
                        const newVoteValues = {
                          ...voteValues,
                          [VoteValueType.Absten]: getVoterExrcsAmount(
                            voter,
                            agenda.agendaSeqno
                          )
                        };
                        return {
                          ...value,
                          ...initValue,
                          //[key]: voteValue,
                          //...voteValues,
                          ...newVoteValues
                        };
                      } else {
                        return {
                          ...value,
                          ...initValue,
                          //[key]: voteValue,
                          ...voteValues
                        };
                      }
                    } else {
                      return value;
                    }
                  } else {
                    return {
                      ...value,
                      childs: value.childs.map((child) => {
                        if (
                          value.agendaSeqno === agenda.parentAgendaSeqno &&
                          child.agendaSeqno === agenda.agendaSeqno
                        ) {
                          return {
                            ...child,
                            ...initValue,
                            ...voteValues
                            //[key]: val,
                          };
                        } else {
                          return child;
                        }
                      })
                    };
                  }
                })
              }
            };
          } else {
            return data;
          }
        })
      );
    });
  };

  const getStockData = (agenda) => {
    if (!selectedVoters || selectedVoters.length === 0) {
      return null;
    }
    const agendas =
      selectedVoters &&
      selectedVoters.map((voter) => {
        const data =
          voteData &&
          voteData.find(
            (item) => item.ballot.shareholderSeqno === voter.stockholderSeqno
          );
        const values = data ? data.ballot.values : null;
        if (!values) return null;

        if (agenda.parentAgendaSeqno) {
          // 분리
          const childAgenda = values
            .find((value) => value.agendaSeqno === agenda.parentAgendaSeqno)
            .childs.find((child) => child.agendaSeqno === agenda.agendaSeqno);
          return childAgenda;
        } else {
          return values.find((value) => {
            return agenda.agendaSeqno === value.agendaSeqno ? true : false;
          });
        }
      });

    const agendaStock = agendas.reduce((acc, curr) => {
      return {
        agendaSeqno: agenda.agendaSeqno,
        ayes: acc.ayes + curr.ayes,
        noes: acc.noes + curr.noes,
        abstention: acc.abstention + curr.abstention
      };
    });

    return agendaStock;
  };
  const getVoteButtons = (agenda) => {
    const exeAmount = getExrcsAmount(agenda); // get~~
    const isAbsten = isAbstenAgenda(agenda);
    if (agenda.childs.length === 0) {
      // 비분리(찬반) 투표
      // 분리 투표
      const data = getStockData(agenda);
      return (
        <VoteButtonContainer>
          <VoteButton
            size={VOTE_BUTTON_SIZE.Short}
            type={VOTE_BUTTON_TYPE.Stock}
            stockAmount={data ? data[VoteValueType.Yes] : 0}
            onClick={() =>
              selectedVoters.length < 2
                ? onVote(agenda, { [VoteValueType.Yes]: exeAmount })
                : openVotingExercisePopup(agenda)
            }
            isAbsten={isAbsten}
          >
            찬성
          </VoteButton>
          <VoteButton
            size={VOTE_BUTTON_SIZE.Short}
            type={VOTE_BUTTON_TYPE.Stock}
            stockAmount={data ? data[VoteValueType.No] : 0}
            onClick={() =>
              selectedVoters.length < 2
                ? onVote(agenda, { [VoteValueType.No]: exeAmount })
                : openVotingExercisePopup(agenda)
            }
            isAbsten={isAbsten}
          >
            반대
          </VoteButton>
          {useDiverse && (
            <VoteButton
              size={VOTE_BUTTON_SIZE.Short}
              onClick={() => {
                if (selectedVoters.length > 1) {
                  openModal(
                    '확인',
                    '선택된 주주가 2명 이상일 경우 불통일 행사를 할 수 없습니다.'
                  );
                } else {
                  diverseHandler(agenda);
                }
              }}
              isAbsten={isAbsten}
              //disabled={selectedVoters.length > 1}
            >
              불통일
            </VoteButton>
          )}
        </VoteButtonContainer>
      );
    } else if (agenda.childs.length !== 0 && agenda.concentrateVote) {
      // 집중 투표
      return (
        <>
          <VoteButtonContainer>
            {agenda.childs.map((child, idx) => {
              /*
              const data = getStockData(child);

              const stock = data?.childs.find(
                (item) => item.agendaSeqno === child.agendaSeqno,
              );
              */
              const stock = getStockData(child);
              return (
                <VoteButton
                  key={`VoteButton-${idx}`}
                  size={VOTE_BUTTON_SIZE.Short}
                  type={VOTE_BUTTON_TYPE.Stock}
                  stockAmount={stock ? stock[VoteValueType.Yes] : 0}
                  isAbsten={isAbsten}
                  onClick={() => concentrateHandler(agenda, selectedVoters)}
                >
                  {`${child.agendaName}`}
                </VoteButton>
              );
            })}
          </VoteButtonContainer>
          <VoteButtonContainer>
            <VoteButton
              size={VOTE_BUTTON_SIZE.Long}
              type={VOTE_BUTTON_TYPE.Foucs}
              onClick={() => concentrateHandler(agenda, selectedVoters)}
              isAbsten={isAbsten}
            >
              집중 투표 행사하기
            </VoteButton>
          </VoteButtonContainer>
        </>
      );
    } else {
      // 잘못 된 의안
    }
  };

  const diverseHandler = (agenda) => {
    openModal(
      '불통일 행사',
      <DiversePopup agenda={agenda} voters={selectedVoters} onVote={onVote} />
    );
  };

  const setDiversReason = (file, content) => {
    if (file) {
      const exerciseData = {
        exerciseDateTime: getCurrentDatetime(),
        exerciseContent: content,
        fileName: file.fileName,
        filePath: file.filePath,
        fileSize: file.fileSize
      };
      const newVoteData = voteData.map((data) => {
        if (
          selectedVoters.find(
            (voter) => data.ballot.shareholderSeqno === voter.stockholderSeqno
          )
        ) {
          return {
            ...data,
            diversityExercise: exerciseData
          };
        } else {
          return data;
        }
      });
      setUseDiverse(true);
      setVoteData(newVoteData);
      console.log('newVoteData');
      console.log(newVoteData);
    }
  };

  const onChangeElectronicProxyName = (name) => {
    setElectronicProxyName(name);
    const newVoteData = voteData.map((data) => {
      if (
        selectedVoters.find(
          (voter) => data.ballot.shareholderSeqno === voter.stockholderSeqno
        )
      ) {
        return {
          ...data,
          electronicProxyName: name
        };
      } else {
        return data;
      }
    });
    setVoteData(newVoteData);
  };
  const openVotingExercisePopup = (agenda) => {
    openModal(
      '의결권 행사',
      <VotingExercisePopup
        agenda={agenda}
        voters={selectedVoters}
        voteData={voteData}
        setVoteData={setVoteData}
      />
    );
  };

  const openDiverseReasonPopup = () => {
    openModal(
      '불통일 행사',
      <DiverseReasonPopup setDiversReason={setDiversReason} />
    );
  };

  const concentrateHandler = (agenda, selectedVoters) => {
    openModal(
      '집중투표',
      <ConcentratePopup
        agenda={agenda}
        voters={selectedVoters}
        //okHandler={onConcentrateVote}
        okHandler={onConcentrateVote}
      />
    );
  };

  const openWithdrawDiversPopup = () => {
    openModal('알림', <WithdrawDiversePopup okHandler={clearData} />);
  };

  // 주주 행사 가능 주식 계산
  const getVoterExrcsAmount = (voter, agendaSeqno) => {
    const data = voter.voteRights.find(
      (rights) => rights.agendaSeqno === agendaSeqno
    );
    return data.exercisableStockAmmount;
  };

  // 행사 가능 주식수 계산
  const getExrcsAmount = (agenda) => {
    if (!agenda) return 0;
    const agendaSeqno = agenda.agendaSeqno;

    const arr = selectedVoters?.map((item) => {
      const data = item.voteRights.find(
        (rights) => rights.agendaSeqno === agendaSeqno
      );
      return data?.exercisableStockAmmount;
    });
    return arr && arr.length !== 0 ? arr.reduce((acc, curr) => acc + curr) : 0;
  };

  const voteCancel = (signData) => {
    const data = {
      proxies: signData
    };
    dispatch(fetchPutElectionsAgmCancelVoteAction({ agmSeqno, data })).then(
      (res) => {
        setModalConfig();
        if (!res.error) {
          clearData();
          // closeModal();
          openModal(
            '확인',
            '성공적으로 취소되었습니다.\n주주총회 목록으로 이동합니다.',
            () => history.push('/shareholder/my-agms')
          );
        } else {
          openModal('에러', res.error.user_message, EMPTY_FUNCTION);
        }
      }
    );
  };

  const vote = (signData) => {
    closeModal();
    const data = {
      method: exerciseMethod,
      votes: signData
    };
    dispatch(fetchPostElectionsAgmVoteAction({ agmSeqno, data })).then(
      (res) => {
        if (!res.error && Array.isArray(res.data)) {
          if (exerciseMethod === ExerciseMethod.VOTE) {
            openModal(
              '의결권 행사',
              '전자의결권이 행사되었습니다\n주주총회 목록으로 이동합니다.',
              () => history.push('/shareholder/my-agms')
            );
          } else {
            openModal(
              '의결권 행사',
              '전자의결권 위임이 행사되었습니다\n주주총회 목록으로 이동합니다.',
              () => history.push('/shareholder/my-agms')
            );
          }
        } else {
          closeModal();
          if (res.error) {
            openModal('의결권 행사', res.error.user_message, EMPTY_FUNCTION);
          } else {
            openModal('의결권 행사', res.data.user_message, EMPTY_FUNCTION);
          }
        }
      }
    );
  };

  const voteCancelSign = () => {
    if (isMobile) {
      openModal('확인', '모바일 환경에서는 의결권을 행사할 수 없습니다.');
      return;
    }

    const data = [
      ...selectedVoters.map((item) => {
        return {
          securitiesCorpSeqno: 1,
          agmSeqno: agmSeqno,
          shareholderSeqno: item.stockholderSeqno,
          userSeqno: userSeqno,
          exerciseMethod: item.exerciseMethod
        };
      })
    ];

    setModalConfig({
      buttons: [
        <CancelBtn onClick={closeModal} />,
        <OkBtn
          onClick={() =>
            idpwsign(
              SIGN_TYPE.PERSON,
              voteCancel,
              data,
              SIGN_DATA_TYPE.VOTE_CANCEL
            )
          }
        />
      ]
    });
    if (exerciseMethod === ExerciseMethod.VOTE) {
      openModal('의결권 행사', <>의결권 행사를 취소하시겠습니까?</>);
    } else {
      openModal('전자의결권 위임', <>전자의결권 위임을 취소하시겠습니까?</>);
    }
  };

  const voteSign = () => {
    if (isMobile) {
      openModal('확인', '모바일 환경에서는 의결권을 행사할 수 없습니다.');
      return;
    }

    if (selectedVoters.length === 0) {
      openModal('확인', '의결권을 행사할 주주를 선택해주세요');
      return;
    }

    let checkVote = true;
    let values = voteData[0].ballot.values;
    let defaultValues = cleanData[0].ballot.values;
    for (let i = 0; i < values.length; i++) {
      if (Object.keys(values[i]).indexOf('childs') != -1) {
        if (
          JSON.stringify(values[i].childs) ===
          JSON.stringify(defaultValues[i].childs)
        ) {
          checkVote = false;
          break;
        }
      } else {
        if (JSON.stringify(values[i]) === JSON.stringify(defaultValues[i])) {
          checkVote = false;
          break;
        }
      }
      // Object.keys(a[1]).contains('childs')
    }

    if (!checkVote) {
      openModal('확인', '의결권을 모두 행사해 주세요');
      return;
    }
    /*
    if (
      JSON.stringify(voteData[0].ballot.values) ===
      JSON.stringify(cleanData[0].ballot.values)
    ) {
      openModal('확인', '의결권을 모두 행사해 주세요');
      return;
    }
    */

    const voterData = [
      ...voteData.filter((data) =>
        selectedVoters.find(
          (voter) => voter.stockholderSeqno === data.ballot.shareholderSeqno
        )
      )
    ];

    console.log('voterData');
    console.log(voterData);

    setModalConfig({
      buttons: [
        <CancelBtn onClick={closeModal} />,
        <OkBtn
          onClick={() =>
            idpwsign(SIGN_TYPE.PERSON, vote, voterData, SIGN_DATA_TYPE.VOTE)
          }
        />
      ]
    });
    if (exerciseMethod === ExerciseMethod.VOTE) {
      openModal(
        '의결권 행사',
        <>
          의결권을 행사하시겠습니까?
          <br />
          의결권 행사는 전자투표 기간 내 언제든 수정 가능합니다.
        </>
      );
    } else {
      openModal(
        '전자의결권 위임',
        <>
          전자의결권 위임을 행사하시겠습니까?
          <br />
          전자위임은 수정은 불가하며, 취소만 가능합니다.
        </>
      );
    }
  };

  const getVoteButtonName = () => {
    const isProxy = selectedVoters.find(
      (voter) => voter.exerciseMethod === ExerciseMethod.PROXY
    );

    const isVote = selectedVoters.find(
      (voter) => voter.exerciseMethod === ExerciseMethod.VOTE
    );

    let buttonName = '전자의결권 행사';
    if (exerciseMethod === ExerciseMethod.PROXY) {
      buttonName = '전자위임장 수여';
    }
    if (isProxy || isVote) {
      if (exerciseMethod === ExerciseMethod.PROXY) {
        buttonName = '전자위임장 재수여';
      } else {
        buttonName = '전자의결권 수정';
      }
    }
    return buttonName;
  };

  const getVoteCancelButtonName = () => {
    const isProxy = selectedVoters.find(
      (voter) => voter.exerciseMethod === ExerciseMethod.PROXY
    );

    const isVote = selectedVoters.find(
      (voter) => voter.exerciseMethod === ExerciseMethod.VOTE
    );

    if (isProxy && isVote) {
      return '행사 취소';
    } else if (isProxy) {
      return '전자위임장 수여 취소';
    } else if (isVote) {
      return '전자의결권 행사 취소';
    } else {
      return '전자의결권 행사 취소';
    }
  };

  const onCleanData = (handler) => {
    setModalConfig({
      buttons: [
        <CancelBtn onClick={closeModal} />,
        <OkBtn
          onClick={() => {
            setVoteData(cleanData);
            if (typeof handler === 'function') {
              handler();
              closeModal();
            } else {
              closeModal();
            }
          }}
        />
      ]
    });
    openModal(
      '확인',
      <>
        <p>
          이미 투표를 진행한 주주가 포함되있을 시 기존 투표값을 초기화 후 진행
          가능합니다.
        </p>
        <p>초기화 하시겠습니까?</p>
        <p>
          <b>초기화 후 의결권을 행사하여야 적용됩니다.</b>
        </p>
      </>
    );
  };

  const isCleanData = () => {
    const cleanDataStr = JSON.stringify(cleanData);
    const voteDataStr = JSON.stringify(voteData);
    return cleanDataStr === voteDataStr;
  };

  const selectNonParticipation = () => {
    setSelectedVoters(voters.filter((voter, idx) => !voter.excerised));
  };

  const voter = agmInfo?.voters[0];

  return (
    <Page>
      {isMobile && (
        <PageHeader
          title={
            exerciseMethod === ExerciseMethod.VOTE ? '투표하기' : '위임장 수여'
          }
        />
      )}
      {isMobile && (
        <h3 style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>
          모바일 환경에서는 의결권을 행사할 수 없습니다.
        </h3>
      )}
      <PageInnerListWrap>
        <CertificateInfo
          isVoteScreen={true}
          info={agmInfo}
          useDiverse={useDiverse}
          selectedVoters={selectedVoters}
          stockholders={stockholders}
          setSelectedVoters={selectedVoterHandler}
          openWithdrawDiversPopup={openWithdrawDiversPopup}
          openDiverseReasonPopup={openDiverseReasonPopup}
          onCleanData={onCleanData}
          isCleanData={isCleanData()}
          selectNonParticipation={selectNonParticipation}
          electronicProxyName={electronicProxyName}
          onChangeElectronicProxyName={onChangeElectronicProxyName}
        />
        <VoteBoxList>
          {agendas &&
            agendas.map((agenda, idx) => {
              if (agenda.childs.length !== 0 && !agenda.concentrateVote) {
                return (
                  <VoteAgendaBox
                    key={`voteAgendaBox_${agenda.agendaSeqno}_${idx}`}
                  >
                    <VoteAgendaBoxTitle
                      agenda={agenda}
                      //useAbsten={true}
                      //isAbsten={true}
                    />
                    {agenda.childs.map((child, childIdx) => {
                      return (
                        <VoteChildAgendaBox
                          key={`voteChildAgendaBox_${childIdx}`}
                          agenda={child}
                          stockAmount={getExrcsAmount(child)}
                          useAbsten={true}
                          isAbsten={isAbstenAgenda(child)}
                          onAbstenClick={() =>
                            onVote(child, {
                              [VoteValueType.Absten]: getExrcsAmount(child)
                            })
                          }
                        >
                          {getVoteButtons(child)}
                        </VoteChildAgendaBox>
                      );
                    })}
                  </VoteAgendaBox>
                );
              } else {
                return (
                  <VoteAgendaBox
                    key={`voteAgendaBox_${agenda.agendaSeqno}_${idx}`}
                  >
                    <VoteAgendaBoxTitle
                      agenda={agenda}
                      useAbsten={true}
                      isAbsten={isAbstenAgenda(agenda)}
                      onAbstenClick={() => {
                        if (agenda.concentrateVote) {
                          onConcentrateAbsten(agenda);
                        } else {
                          onVote(
                            agenda,
                            {
                              [VoteValueType.Absten]: getExrcsAmount(agenda)
                            },
                            true
                          );
                        }
                      }}
                    />
                    <ExercisableStockAmount
                      //stockAmount={agenda.exercisableStockAmmount}
                      stockAmount={getExrcsAmount(agenda)}
                    />
                    {getVoteButtons(agenda)}
                  </VoteAgendaBox>
                );
              }
            })}
        </VoteBoxList>
      </PageInnerListWrap>
      {voter?.exercisablePreferredStockAmount > 0 ? (
        <ButtonGroup voteButton={isMobile}>
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Medium}
            onClick={voteSign}
          >
            {getVoteButtonName()}
          </Button>
          {getVoteCancelButtonName() && (
            <Button
              type={ButtonTypes.Basic}
              size={ButtonSize.Medium}
              onClick={voteCancelSign}
            >
              {getVoteCancelButtonName()}
            </Button>
          )}
        </ButtonGroup>
      ) : (
        <ButtonGroup voteButton={isMobile}>
          <Button
            type={ButtonTypes.Disabled}
            size={ButtonSize.Medium}
            onClick={() => {}}
          >
            {getVoteButtonName()}
          </Button>
          {
            <Button
              type={ButtonTypes.Disabled}
              size={ButtonSize.Medium}
              onClick={() => {}}
            >
              {getVoteCancelButtonName()}
            </Button>
          }
        </ButtonGroup>
      )}
    </Page>
  );
};

export default VotePage;
