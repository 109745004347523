import PropTypes from 'prop-types'; // ES6
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ModalContext } from '../../context/ModalContext';
import {
  CancelBtn,
  OkBtn
} from '../../pages/shareholder/vote/main/components/popup/common/PopupButton';
import { Icons } from '../../styles/Icons';
import Button, { ButtonSize, ButtonTypes } from './Button';

const FileButton = (props) => {
  const hiddenFileInput = useRef(null);
  const { isUploaded } = props;
  const [uploadedFile, setUploadedFile] = useState(null);
  const { openModal, closeModal, setModalConfig } = useContext(ModalContext);

  const handleClick = (event) => {
    if (isUploaded || uploadedFile) {
      setModalConfig({
        buttons: [
          <OkBtn title="등록" onClick={deleteAndUploadFile} />,
          <CancelBtn onClick={closeModal} />
        ]
      });
      openModal(
        '확인',
        <>
          <p>업로드 시 기존 파일이 삭제됩니다.</p>
          <p>등록 하시겠습니까?</p>
        </>
      );
    } else {
      hiddenFileInput.current.click();
    }
  };

  const deleteAndUploadFile = () => {
    closeModal();
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const { extension } = props;
    if (fileUploaded) {
      if (extension) {
        const ext = fileUploaded.name
          .substring(
            fileUploaded.name.lastIndexOf('.') + 1,
            fileUploaded.name.length
          )
          .toLowerCase();

        if (Array.isArray(extension)) {
          if (!extension.includes(ext)) {
            openModal(
              '파일 확장자 오류',
              // `파일 확장자는 ${extension} 만 가능합니다`,
              `.${ext}는 허용되지 않은 파일 확장자입니다.`
            );
            setUploadedFile(false);
            hiddenFileInput.current.value = '';
            return;
          }
        } else {
          if (extension !== ext) {
            openModal(
              '파일 확장자 오류',
              // `파일 확장자는 ${extension}만 가능합니다`,
              `.${ext}는 허용되지 않은 파일 확장자입니다.`
            );
            setUploadedFile(false);
            hiddenFileInput.current.value = '';
            return;
          }
        }

        // 파일 용량 체크
        // TODO 임시로 용랑 체크 (20MB)
        if (fileUploaded.size > 20 * 1000 * 1000) {
          openModal(
            '파일 사이즈 오류',
            '첨부파일 용량이 초과되었습니다. (최대 20MB)'
          );
          setUploadedFile(false);
          hiddenFileInput.current.value = '';
          return;
        }
      }

      setUploadedFile(true);
      if (typeof props.handleFile === 'function') {
        props.handleFile(props.fileType, fileUploaded);
      }
    }
  };

  useEffect(() => {
    if (props.clearFile) {
      hiddenFileInput.current.value = '';
      setUploadedFile(false);
    }
  }, [props.clearFile]);

  return (
    <>
      <Button
        {...props}
        type={
          isUploaded || uploadedFile ? ButtonTypes.Primary : ButtonTypes.Basic
        }
        endIcon={
          isUploaded || uploadedFile ? Icons.paper_check : Icons.paper_add
        }
        onClick={handleClick}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={handleChange}
      />
    </>
  );
};

FileButton.propTypes = {
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  // type
  type: PropTypes.oneOf(Object.values(ButtonTypes)),

  // icon
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,

  // action
  onClick: PropTypes.func,
  handleFile: PropTypes.func,
  extension: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

FileButton.defaultProps = {
  //startIcon: Icons.search_blue,
  extension: [
    'jpg',
    'png',
    'jpeg',
    'doc',
    'docx',
    'hwp',
    'pdf',
    'ppt',
    'psd',
    'txt',
    'xls',
    'xlsx'
  ]
};

export default FileButton;
