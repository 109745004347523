import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import DataTable, {
  DataType
} from '../../../../components/datatable/DataTable';
import ListHeader from '../../../../components/layout/ListHeader';
import Section from '../../../../components/layout/Section';
import { MODAL_SIZE } from '../../../../components/modal/Modal';
import { ModalContext } from '../../../../context/ModalContext';
import {
  fetchGetAgmLimitsAction,
  fetchPutAgmLimitsDeleteAction
} from '../../../../services/api/agm/promiseActions';
import { VotingRightsLimitCode } from '../../../../utils/constant/AgmConstant';
import { EMPTY_FUNCTION } from '../../../../utils/FunctionUtil';
import { checkUndefinedNum } from '../../../../utils/StringUtil';
import { AGM_REGISTER_STEPS } from '../AgmRegister';
import AgmRegisterPageButton from '../main/components/AgmRegisterPageButton';
import AgmRegisterStep3Modal from './AgmRegisterStep3Modal';

/*
  주주총회 등록 - 03. 의결권 행사 제한 등록
*/
const AgmRegisterStep3 = ({ register, handleFile, moveStep }) => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const [votingRightsLimits, setVotingRightsLimits] = useState([]);
  const [reRender, setReRender] = useState(true);
  const [clearSeleted, setClearSeleted] = useState(false);

  // 의결권 제한 조회
  useEffect(() => {
    agmSeqno &&
      reRender &&
      dispatch(fetchGetAgmLimitsAction({ agmSeqno })).then((res) => {
        if (!res.error) {
          // eslint-disable-next-line array-callback-return
          const arr = res.data.votingRightsLimits.sort(function (a, b) {
            if (a.screenOrder > b.screenOrder) return -1;
            if (a.screenOrder === b.screenOrder) return 0;
            if (a.screenOrder < b.screenOrder) return 1;
          });
          arr.forEach((item) => {
            item.totalStockAmount =
              item.commonStockAmount +
              checkUndefinedNum(item.exercisablePreferredStockAmount);
          });
          setVotingRightsLimits(arr);
          setReRender(false);
        }
      });
  }, [dispatch, reRender, agmSeqno]);

  const openLimitsRegisterModal = () => {
    setModalConfig({ size: MODAL_SIZE.MEDIUM, isNoButton: true });
    openModal(
      '의결권 행사 제한 (의결권 없는 우선주는 고려하지 않음)',
      <AgmRegisterStep3Modal
        agmSeqno={agmSeqno}
        closeModal={closeModal}
        setReRender={setReRender}
        votingRightsLimits={votingRightsLimits}
      />,
      EMPTY_FUNCTION,
      true
    );
  };
  const columns = [
    {
      title: '이름',
      key: 'stockholderName',
      col: '8%'
    },
    {
      title: '주민번호/사업자번호',
      key: 'stockholderRealNameNumber',
      col: '14%'
    },
    {
      title: '제한 사유',
      key: 'votingRightsLimitContent',
      col: '16%'
    },
    {
      title: '안건',
      key: 'agendaName',
      col: '15%'
    },
    {
      // title: ['주주명부상 보유주식수', '(우선주 제외)'],
      title: ['의결권 있는', '주식수'],
      key: 'totalStockAmount',
      type: DataType.NUMBER,
      col: '11%'
    },
    {
      title: ['의결권 없는', '주식수'],
      key: 'noVotingRightsStockAmmount',
      type: DataType.NUMBER
    },
    {
      title: ['의결권 제한', '주식수'],
      key: 'votingRightsLimitStockAmmount',
      type: DataType.NUMBER
    },
    {
      title: ['행사 가능', '주식수'],
      key: 'exercisableStockAmmount',
      type: DataType.NUMBER
    }
  ];

  const onDeleteLimits = useCallback(() => {
    if (selectedRowKeys.length !== 0) {
      const data = selectedRowKeys.map((index) => {
        return {
          agendaSeqno: votingRightsLimits[index].agendaSeqno,
          limitSeqno: votingRightsLimits[index].limitSeqno,
          stockholderSeqno: votingRightsLimits[index].stockholderSeqno,
          votingRightsLimitCode: votingRightsLimits[index].votingRightsLimitCode
        };
      });

      if (
        !!data.find(
          (item) =>
            item.votingRightsLimitCode ===
            VotingRightsLimitCode.TREASURY_STOCK.code
        )
      ) {
        openModal(
          '에러',
          '자사주(의결권 없는 주식수)가 포함된 제한 내역은 삭제할 수 없습니다.'
        );
        return;
      }

      dispatch(fetchPutAgmLimitsDeleteAction({ agmSeqno, data })).then(
        (res) => {
          if (!res.error) {
            openModal('알림', '삭제되었습니다.');
            setClearSeleted(true);
            setTimeout(() => setClearSeleted(false), 200);
            setSelectedRowKeys([]);
            setReRender(true);
          } else {
            console.log('삭제 실패');
          }
        }
      );
    }
  }, [agmSeqno, dispatch, selectedRowKeys, votingRightsLimits]);
  const actionItems = () => {
    return (
      <>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Table}
          onClick={openLimitsRegisterModal}
        >
          추가
        </Button>
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Table}
          onClick={onDeleteLimits}
        >
          삭제
        </Button>
      </>
    );
  };
  const descArr = [
    '추가버튼을 눌러 특정 주주의 의결권 제한을 설정할 수 있습니다.',
    '우선주는 의결권 없는 주식수에 자동 반영됩니다',
    '한 주주가 여러건의 의결권 제한이 있는 경우, 실제 제한 수량 합계는 전자투표권자 명부에서 확인하실 수 있습니다.'
  ];
  const onRowClick = (e) => {
    setModalConfig({ size: MODAL_SIZE.MEDIUM, isNoButton: true });
    openModal(
      '의결권 행사 제한',
      <AgmRegisterStep3Modal
        closeModal={closeModal}
        setReRender={setReRender}
        votingRightsLimits={votingRightsLimits}
        isUpdateFlag={true}
        rowIndex={e.currentTarget.rowIndex - 1}
        agmSeqno={agmSeqno}
      />,
      EMPTY_FUNCTION,
      true
    );
  };

  // 나중에 파일로 의결권 제한 업로드 할 때 사용될 부분
  // TODO check 김호수
  /*
  const onClickGetLimitsFile = () => {
    dispatch(fetchGetFilesLimitsSampleAction()).then((res) => {
      console.log(res);
    });
  };
  const onClickPostLimitsFile = (fileType, uploadedFile) => {
    const data = new FormData();
    data.append('file', uploadedFile);
    dispatch(fetchPostAgmLimitsFileUploadAction({ agmSeqno, data })).then(
      (res) => {
        console.log(res);
      }
    );
  };
  */

  const moveNextStep = () => {
    typeof moveStep === 'function' &&
      moveStep(AGM_REGISTER_STEPS.ELECTION_VOTER);
  };
  return (
    <>
      <Section wide>
        <ListHeader title="의결권 제한" />
        <DataTable
          columns={columns}
          dataSource={votingRightsLimits}
          onSelected={setSelectedRowKeys}
          actionItems={actionItems()}
          description={descArr}
          filterConfig={{
            desc: `총 ${votingRightsLimits.length}건`,
            filterItems: ['단위 : 주식수(주)']
          }}
          onRowClick={onRowClick}
          clearSeleted={clearSeleted}
        />
      </Section>
      {/* <Section>
        <FileDownButton
          type={ButtonTypes.Defaults}
          size={ButtonSize.Default}
          onClick={onClickGetLimitsFile}
        >
          서식 다운로드
        </FileDownButton>
        <FileButton
          type={ButtonTypes.Basic}
          size={ButtonSize.Default}
          endIcon={Icons.paper_add}
          handleFile={onClickPostLimitsFile}
        >
          의결권 제한내역 업로드
        </FileButton>
      </Section> */}
      <AgmRegisterPageButton
        usePrev
        useNext
        step={AGM_REGISTER_STEPS.LIMITS}
        handleNextBtn={moveNextStep}
      />
    </>
  );
};

export default AgmRegisterStep3;
