import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import Input, { ErrorTypes } from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import { PopupSearchInput } from '../../../../components/input/SearchInput';
import Select from '../../../../components/input/Select';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
import { LayerBodyContent } from '../../../../components/modal/LayerBodyContent';
import { MODAL_SIZE } from '../../../../components/modal/Modal';
import Notice, { NoticeTypes } from '../../../../components/notice/Notice';
import {
  fetchGetAgmLimitsStockholdersAction,
  fetchGetAgmStockholderAction,
  fetchGetAgmVotingRightsTotalAction,
  fetchPostAgmBrowseIndividualAction,
  fetchPostAgmLimitsAction,
  fetchPutAgmLimitsAction
} from '../../../../services/api/agm/promiseActions';
import {
  getConstNameByCode,
  getConstNoVotingRightsByCode,
  getConstVotingRightsLimitByCode,
  VotingRightsLimitCode
} from '../../../../utils/constant/AgmConstant';
import { checkUndefinedNum } from '../../../../utils/StringUtil';
import SubModal from '../step4/main/SubModal';

const AgmRegisterStep3Modal = (props) => {
  const DEFAULT_SELECT_CODE = '-1';
  const ALL_SELECT_CODE = '0';
  const {
    closeModal,
    setReRender,
    votingRightsLimits = [],
    isUpdateFlag = false,
    rowIndex,
    agmSeqno
  } = props;
  const dispatch = useDispatch();
  const [isEtc, setIsEtc] = useState(false);
  const [selectedLimitReason, setSelectedLimitReason] =
    useState(ALL_SELECT_CODE);
  const {
    register,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'all' });
  const [agendas, setAgendas] = useState([]);
  const [stockholder, setStockholder] = useState({});
  const [votingRightsLimit, setVotingRightsLimit] = useState(true);
  const [noVotingRights, setNoVotingRights] = useState(true);
  const [stockholderLimitsList, setStockholderLimitsList] =
    useState(votingRightsLimits);
  const [stockholderSeqnoInput, setStockholderSeqnoInput] = useState();
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSumStockAmmount, setIsSumStockAmmount] = useState(false);
  const selAgendaRef = useRef(votingRightsLimits[rowIndex]);

  const totalStockAmount = useMemo(() => {
    return (
      stockholder.commonStockAmount +
      checkUndefinedNum(stockholder.exercisablePreferredStockAmount)
    );
  }, [stockholder]);

  const onVoterSearch = useCallback(
    (value) => {
      const data = { identificationNumber: value };
      data.agmSeqno = agmSeqno;
      setStockholderSeqnoInput(value);
      dispatch(fetchGetAgmStockholderAction(data))
        .then((res) => {
          if (!res.error) {
            const stockholder = res.data;
            if (!!Object.keys(stockholder).length) {
              setStockholder(stockholder);
            } else {
              setErrorMessage(
                '잘못된 실명번호입니다.<br />실명번호를 확인 후 다시 검색해주세요.'
              );
              setSubModalOpen(true);
            }
          } else {
            setErrorMessage(res.error.dev_message);
            setSubModalOpen(true);
          }
        })
        .catch((e) => {
          console.log('!!!!! error !!!!!');
          console.log(e);
        });
    },
    [agmSeqno, dispatch]
  );

  useEffect(() => {
    dispatch(fetchGetAgmVotingRightsTotalAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        setAgendas(res.data.totalVotingRights);
      }
    });
    if (isUpdateFlag) {
      dispatch(
        fetchPostAgmBrowseIndividualAction({
          agmSeqno,
          data: {
            shareholderSeqno: selAgendaRef.current?.stockholderSeqno
          }
        })
      ).then((res) => {
        if (!res.error) {
          // Todo 임시
          setTimeout(() => {
            reset(selAgendaRef.current);
          }, 500);
          onVoterSearch(atob(res.data.stockholderRealNameNumber));
          setValue(
            'searchInputValue',
            atob(res.data.stockholderRealNameNumber)
          );
        }
      });
    }
  }, [dispatch, agmSeqno, isUpdateFlag, reset, onVoterSearch]);

  useEffect(() => {
    // 제한 사유가 0이 아닐 때,
    if (selectedLimitReason !== ALL_SELECT_CODE) {
      selectedLimitReason === VotingRightsLimitCode.ETC.code && setIsEtc(true);
      selectedLimitReason !== VotingRightsLimitCode.ETC.code && setIsEtc(false);

      setNoVotingRights(
        getConstNoVotingRightsByCode(VotingRightsLimitCode, selectedLimitReason)
      );
      setVotingRightsLimit(
        getConstVotingRightsLimitByCode(
          VotingRightsLimitCode,
          selectedLimitReason
        )
      );
    } else {
      setNoVotingRights(true);
      setVotingRightsLimit(true);
    }
  }, [selectedLimitReason]);

  const checkLimitAgenda = useCallback(
    (agendaSeqno) => {
      if (!!stockholderLimitsList.length) {
        return !!stockholderLimitsList.find(
          (limit) => limit.agendaSeqno === parseInt(agendaSeqno)
        );
      } else {
        return false;
      }
    },
    [stockholderLimitsList]
  );

  const resetWatchFields = useCallback(
    (agendaSeqno) => {
      if (agendaSeqno === DEFAULT_SELECT_CODE) {
        setValue('noVotingRightsStockAmmount', 0);
        setValue('votingRightsLimitStockAmmount', 0);
      } else {
        if (checkLimitAgenda(agendaSeqno)) {
          const agenda = stockholder.voteRights?.find(
            (agenda) => agenda.agendaSeqno === parseInt(agendaSeqno)
          );
          if (typeof agenda !== 'undefined') {
            setValue(
              'noVotingRightsStockAmmount',
              agenda.noVotingRightsStockAmmount
            );
            setValue(
              'votingRightsLimitStockAmmount',
              agenda.votingRightsLimitStockAmmount
            );
          }
        } else {
          console.log('제한 안 됨');
          // resetAllFields(agendaSeqno);
        }
      }
    },
    [setValue, stockholder.voteRights, checkLimitAgenda]
  );

  const resetAllFields = (agendaSeqno) => {
    if (!!Object.keys(stockholder).length) {
      setValue('commonStockAmount', totalStockAmount);
      setValue('exercisableStockAmmount', totalStockAmount);
      setValue('noVotingRightsStockAmmount', 0);
      setValue('votingRightsLimitStockAmmount', 0);
      isSumStockAmmount && setValue('sumStockAmmount', getSumStockAmmount());

      setValue('agendaSeqno', agendaSeqno);
    }
  };

  useEffect(() => {
    if (!!Object.keys(stockholder).length) {
      if (!isUpdateFlag) {
        reset(stockholder);
        resetAllFields(DEFAULT_SELECT_CODE);
      } else {
        // setValue('searchInputValue', stockho);
      }

      // 단일주주 의결권 제한 조회
      const data = {
        securitiesCorpSeqno: stockholder.securitiesCorpSeqno,
        agmSeqno: agmSeqno,
        stockholderSeqno: stockholder.stockholderSeqno
      };
      dispatch(fetchGetAgmLimitsStockholdersAction(data)).then((res) => {
        if (!res.error) {
          const data = res.data?.votingRightsLimits;
          if (!!data.length) {
            setStockholderLimitsList(data);
            setIsSumStockAmmount(true);
            // setValue('sumStockAmmount', getSumStockAmmount());
          } else {
            setStockholderLimitsList([]);
          }
        }
      });

      if (isUpdateFlag) {
        const limitObj = stockholderLimitsList.find(
          // (obj) => stockholder.stockholderSeqno === obj.stockholderSeqno,
          (obj) => checkEqualAgenda(selAgendaRef.current, obj)
        );
        if (typeof limitObj !== 'undefined') {
          onChangeLimitReason(limitObj.votingRightsLimitCode);
        }
      }
    }
  }, [stockholder]);

  const checkEqualAgenda = (base, target) => {
    if (base.agendaSeqno !== target.agendaSeqno) {
      return false;
    } else if (base.stockholderSeqno !== target.stockholderSeqno) {
      return false;
    } else if (base.limitSeqno !== target.limitSeqno) {
      return false;
    } else if (base.securitiesCorpSeqno !== target.securitiesCorpSeqno) {
      return false;
    } else {
      return true;
    }
  };

  const getSumStockAmmount = useCallback(() => {
    let ammount = 0;
    stockholderLimitsList.forEach((item) => {
      if (!isUpdateFlag || !checkEqualAgenda(selAgendaRef.current, item)) {
        ammount +=
          item.votingRightsLimitStockAmmount + item.noVotingRightsStockAmmount;
      }
    });
    return totalStockAmount - ammount;
  }, [stockholderLimitsList, isUpdateFlag]);

  const watchFields = watch([
    'noVotingRightsStockAmmount',
    'votingRightsLimitStockAmmount'
  ]);

  useEffect(() => {
    const sum = watchFields.reduce((acc, cur) => Number(acc) + Number(cur), 0);
    // 211025 수정. if문 추가.
    if (totalStockAmount > 0) {
      setValue('commonStockAmount', totalStockAmount);
      setValue('exercisableStockAmmount', totalStockAmount - sum);
      setValue('sumStockAmmount', getSumStockAmmount() - sum);
    }
  }, [watchFields, setValue, totalStockAmount, getSumStockAmmount]);

  const submit = (data) => {
    const isPresent = stockholderLimitsList.find(
      (item) => item.agendaSeqno !== 0 && data.agendaSeqno === item.agendaSeqno
    );
    if (!isUpdateFlag && !!isPresent) {
      setErrorMessage('이미 제한된 안건입니다.');
      setSubModalOpen(true);
    } else if (
      !!getValues('sumStockAmmount') &&
      getValues('sumStockAmmount') < 0
    ) {
      setErrorMessage(
        '최종 의결권 가능 주식 수(e)는 0주보다 작을 수 없습니다.'
      );
      setSubModalOpen(true);
    } else {
      const params = {
        agmSeqno: agmSeqno,
        data: {
          votingRightsLimits: [
            {
              agendaSeqno: Number(data.agendaSeqno),
              stockholderSeqno: stockholder.stockholderSeqno,
              votingRightsLimitCode: data.votingRightsLimitCode,
              votingRightsLimitContent:
                data.votingRightsLimitCode === VotingRightsLimitCode.ETC.code
                  ? data.votingRightsLimitContent
                  : getConstNameByCode(
                      VotingRightsLimitCode,
                      data.votingRightsLimitCode
                    ),
              noVotingRightsStockAmmount: noVotingRights
                ? Number(data.noVotingRightsStockAmmount)
                : 0,
              votingRightsLimitStockAmmount: votingRightsLimit
                ? Number(data.votingRightsLimitStockAmmount)
                : 0,
              exercisableStockAmmount:
                totalStockAmount -
                data.noVotingRightsStockAmmount -
                data.votingRightsLimitStockAmmount,
              screenOrder: 1
            }
          ]
        }
      };
      if (isUpdateFlag) {
        params.data.votingRightsLimits[0].limitSeqno =
          selAgendaRef.current.limitSeqno;
        params.data.votingRightsLimits[0].screenOrder =
          selAgendaRef.current.screenOrder;
      }
      const action = isUpdateFlag
        ? fetchPutAgmLimitsAction
        : fetchPostAgmLimitsAction;
      dispatch(action(params)).then((res) => {
        if (!res.error) {
          if (typeof closeModal === 'function') {
            closeModal();
            setReRender(true);
          }
        }
      });
    }
  };

  const onChangeLimitReason = (value) => {
    // 실명번호 검색 안했을 시
    if (!Object.keys(stockholder).length) {
      setErrorMessage('의결권 행사를 제한할 주주를 먼저 선택해주세요.');
      setSubModalOpen(true);
      setValue('votingRightsLimitCode', '0');
      return;
    }

    // 제한 사유가 '선택해주세요'가 아닐 때,
    if (value !== DEFAULT_SELECT_CODE) {
      setSelectedLimitReason(value);
      value !== VotingRightsLimitCode.ETC.code
        ? setValue(
            'votingRightsLimitContent',
            getConstNameByCode(VotingRightsLimitCode, value)
          )
        : !isUpdateFlag && setValue('votingRightsLimitContent', '');
    } else {
      resetAllFields(DEFAULT_SELECT_CODE);
    }
  };

  const onChangeApplyAgenda = (value) => {
    if (!Object.keys(stockholder).length) {
      setErrorMessage('의결권 행사를 제한할 주주를 먼저 선택해주세요.');
      setSubModalOpen(true);
      setValue('agendaSeqno', DEFAULT_SELECT_CODE);
      return;
    }

    if (value === DEFAULT_SELECT_CODE) {
      resetAllFields(DEFAULT_SELECT_CODE);
    } else {
      resetWatchFields(value);
    }
  };

  const checkETC = (value) => {
    if (value === '') {
      return false;
    }

    if (getValues('votingRightsLimitCode') === VotingRightsLimitCode.ETC.code) {
      if (
        parseInt(getValues('votingRightsLimitStockAmmount')) === 0 &&
        parseInt(getValues('noVotingRightsStockAmmount')) === 0
      ) {
        return false;
      } else if (
        stockholder?.commonStockAmount +
          stockholder?.exercisablePreferredStockAmount -
          parseInt(getValues('votingRightsLimitStockAmmount')) -
          parseInt(getValues('noVotingRightsStockAmmount')) <
        0
      ) {
        return false;
      }
    } else {
      if (!parseInt(value) > 0) {
        return false;
      } else if (
        stockholder?.commonStockAmount +
          stockholder?.exercisablePreferredStockAmount -
          parseInt(value) <
        0
      ) {
        return false;
      }
    }
    return true;
  };

  const clickModalClose = () => {
    setValue('searchInputValue', '');
    setSubModalOpen(false);
  };

  return (
    <>
      <LayerBodyContent>
        <ListHeader small />
        <ListItem>
          <InputBox
            title="실명번호"
            isRequired
            noFlex
            error={errors.searchInputValue?.message}
            errorType={ErrorTypes.ERROR}
          >
            <PopupSearchInput
              placeholder="주민/사업자등록 '-'없이 입력"
              onSearch={onVoterSearch}
              resetValue={stockholderSeqnoInput}
              name="searchInputValue"
              register={register}
              disabled={typeof rowIndex !== 'undefined'}
            />
            <input type="hidden" name="stockholderSeqno" register={register} />
          </InputBox>
          <InputBox title="주주명" isRequired>
            <Input
              name="stockholderName"
              placeholder="홍길동"
              register={register}
              disabled
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox
            title="제한 사유"
            isRequired
            error={errors.votingRightsLimitCode?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Select
              name="votingRightsLimitCode"
              register={register}
              registerOption={{
                validate: {
                  required: (value) =>
                    value !== DEFAULT_SELECT_CODE || '제한 사유를 선택하세요.'
                }
              }}
              onchange={(e) => onChangeLimitReason(e.target.value)}
              optionDisabled={isUpdateFlag ? true : false}
            >
              {Object.keys(VotingRightsLimitCode).map((key, idx) =>
                isUpdateFlag ? (
                  <option
                    key={`limitCode_${idx + 1}`}
                    value={VotingRightsLimitCode[key].code}
                    disabled={isUpdateFlag ? true : false}
                  >
                    {VotingRightsLimitCode[key].name}
                  </option>
                ) : (
                  VotingRightsLimitCode[key].code !==
                    VotingRightsLimitCode.TREASURY_STOCK.code && (
                    <option
                      key={`limitCode_${idx + 1}`}
                      value={VotingRightsLimitCode[key].code}
                      disabled={isUpdateFlag ? true : false}
                    >
                      {VotingRightsLimitCode[key].name}
                    </option>
                  )
                )
              )}
            </Select>
          </InputBox>
          {isEtc ? (
            <InputBox
              title="직접 입력"
              error={errors.votingRightsLimitContent?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                placeholder="제한 사유 입력"
                name="votingRightsLimitContent"
                register={register}
                registerOption={{
                  required: {
                    value: true,
                    message: '제한 사유를 입력해주세요'
                  }
                }}
              />
            </InputBox>
          ) : (
            <InputBox isEmpty />
          )}
        </ListItem>
        <ListItem>
          <InputBox
            title="적용 안건"
            isRequired
            error={errors.agendaSeqno?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Select
              name="agendaSeqno"
              register={register}
              onchange={(e) => onChangeApplyAgenda(e.target.value)}
              registerOption={{
                validate: {
                  required: (value) =>
                    value !== DEFAULT_SELECT_CODE || '적용 안건을 선택하세요.'
                }
              }}
              optionDisabled={isUpdateFlag ? true : false}
            >
              <option
                key="agenda_0"
                value="0"
                disabled={isUpdateFlag ? true : false}
              >
                {checkLimitAgenda(parseInt(ALL_SELECT_CODE))
                  ? `전체 안건 (제한됨)`
                  : `전체 안건`}
              </option>
              {agendas.map((agenda, idx) => (
                <option
                  key={`agenda_${idx + 1}`}
                  value={agenda.agendaSeqno}
                  disabled={isUpdateFlag ? true : false}
                >
                  {checkLimitAgenda(agenda.agendaSeqno)
                    ? `[제 ${agenda.agendaNumber} 안건] ${agenda.agendaName} (제한됨)`
                    : `[제 ${agenda.agendaNumber} 안건] ${agenda.agendaName}`}
                </option>
              ))}
            </Select>
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="의결권 있는 주식 수 (a)">
            <Input
              placeholder="의결권 있는 주식 수"
              disabled
              register={register}
              name="commonStockAmount"
            />
          </InputBox>
          {noVotingRights ? (
            <InputBox
              title={`의결권 없는 주식 수 (b)`}
              isRequired
              error={errors.noVotingRightsStockAmmount?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                name="noVotingRightsStockAmmount"
                placeholder="의결권 없는 주식 수"
                register={register}
                registerOption={{
                  pattern: {
                    value: /^[0-9]+$/g,
                    message: '숫자만 입력 가능합니다.'
                  },
                  validate: {
                    checkETC: (value) =>
                      checkETC(value) || '주식 수를 확인해주세요',
                    onlyNumber: (value) =>
                      !isNaN(parseInt(value)) || '숫자만 입력 가능합니다.'
                  }
                }}
              />
            </InputBox>
          ) : (
            <InputBox isEmpty>
              <Input
                name="noVotingRightsStockAmmount"
                register={register}
                // type="number"
              />
            </InputBox>
          )}
        </ListItem>
        <ListItem>
          {votingRightsLimit ? (
            <InputBox
              title={`의결권 제한 주식 수 ${noVotingRights ? '(c)' : '(b)'}`}
              isRequired
              error={errors.votingRightsLimitStockAmmount?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                name="votingRightsLimitStockAmmount"
                placeholder="의결권 제한 주식 수"
                register={register}
                registerOption={{
                  pattern: {
                    value: /^[0-9]+$/g,
                    message: '숫자만 입력 가능합니다.'
                  },
                  validate: {
                    checkETC: (value) =>
                      checkETC(value) || '주식 수를 확인해주세요',
                    onlyNumber: (value) =>
                      !isNaN(parseInt(value)) || '숫자만 입력 가능합니다.'
                  }
                }}
              />
            </InputBox>
          ) : (
            <InputBox isEmpty>
              <Input
                name="votingRightsLimitStockAmmount"
                // type="number"
                register={register}
              />
            </InputBox>
          )}
          <InputBox
            title={`
          행사 가능 주식 수 ${
            noVotingRights && votingRightsLimit ? '(a-b-c)' : '(a-b)'
          }
          `}
          >
            <Input
              name="exercisableStockAmmount"
              placeholder="행사 가능 주식 수"
              register={register}
              disabled
            />
          </InputBox>
        </ListItem>
        {isSumStockAmmount ? (
          <ListItem>
            <InputBox title="최종 의결권 가능 주식 수(e)">
              <Input name="sumStockAmmount" register={register} readOnly />
            </InputBox>
            <InputBox>
              <Notice
                type={NoticeTypes.PopUp}
                description="최종 의결권 가능 주식은 한 주주가 여러건의 의결권 제한이 있는 경우,<br/>의결권 제한사항들이 반영된 그 의안의 최종 행사 가능한 주식수이다."
              />
            </InputBox>
          </ListItem>
        ) : (
          <></>
        )}
        {selAgendaRef.current?.votingRightsLimitCode !==
        VotingRightsLimitCode.TREASURY_STOCK.code ? (
          <ButtonGroup>
            <Button
              type={ButtonTypes.Primary}
              size={ButtonSize.Popup}
              onClick={handleSubmit(submit)}
            >
              {isUpdateFlag ? '수정' : '등록'}
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </LayerBodyContent>
      {subModalOpen && (
        <SubModal
          layerTitle="에러"
          content={errorMessage}
          modalSize={MODAL_SIZE.SHORT}
          handleModal={clickModalClose}
        />
      )}
    </>
  );
};

export default AgmRegisterStep3Modal;
