import styled from 'styled-components';

const TableBlock = ({ children }) => {
  return <TableCommonBlock>{children}</TableCommonBlock>;
};

export const TableDataBlock = (props) => {
  return (
    <table>
      <colgroup>
        <col width="*" />
        <col width="16%" />
        <col width="14%" />
        <col width="13%" />
        <col width="13%" />
        <col width="15%" />
      </colgroup>
      <thead>
        <tr>
          <th>주주총회명</th>
          <th>주주총회일</th>
          <th>주주총회 구분</th>
          <th>기준일</th>
          <th>행사율</th>
          <th>상태(가결/전체)</th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );
};

export const TableButtonBlock = (props) => {
  return (
    <TableBtnBlock>
      <ul>
        <li>{props.children}</li>
      </ul>
    </TableBtnBlock>
  );
};

const TableCommonBlock = styled.div`
  // .tblcommon-02
  width: 100%;
  border-top: solid 2px #2e487c;
  & > table {
    border: solid 1px #e9ecf4;
    border-collapse: collapse;
    & th {
      border: solid 1px #e9ecf4;
      background: #fbfbfc;
      position: relative;
      padding: 8px 20px 7px;
      height: 32px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
      -webkit-transform: skew(-0.04deg);
      -moz-transform: skew(-0.04deg);
      -ms-transform: skew(-0.04deg);
    }
    & td {
      border: solid 1px #e9ecf4;
      background-color: #fff;
      padding: 8px 20px 7px;
      height: 34px;
      font-size: 15px;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      transform: skew(-0.04deg);
      -webkit-transform: skew(-0.04deg);
      -moz-transform: skew(-0.04deg);
      -ms-transform: skew(-0.04deg);
    }
    & > tbody td {
      background-color: #fff;
    }
  }
`;

export const TableBtnBlock = styled.div`
  // .tblbtn_area
  padding-top: 12px;
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      text-align: left;
      & > button {
        height: 100%;
        min-width: auto;
        align-items: center;
        justify-content: center;
        display: inline-block;
        width: auto;
        margin-left: 4px;
      }
      & > button:first-child {
        margin-left: 0;
      }
    }
    & > li:first-of-type {
      margin-left: 0;
    }
    & > li:only-of-type {
      text-align: center;
    }
    & > li:last-of-type {
      text-align: right;
    }
  }
`;

export default TableBlock;
