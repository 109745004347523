// 의안 분류 코드
export const AgendaCategory = {
  APPROVAL_OF_FINANCIAL_STATEMENTS: { code: '010', name: '재무제표의 승인' },
  CHANGE_THE_ARTICLES_OF_ASSOCIATION: { code: '020', name: '정관변경' },
  DIVIDEND_OF_PROFIT: { code: '030', name: '이익배당' },
  APPOINTMENT_OF_DIRECTORS: { code: '040', name: '이사선임' },
  APPROVAL_OF_REMUNERATION_FOR_DIRECTORS: {
    code: '050',
    name: '이사보수 승인'
  },
  APPOINTMENT_OF_AUDITOR: { code: '060', name: '감사선임' },
  APPROVAL_OF_AUDIT_REMUNERATION: { code: '070', name: '감사보수 승인' },
  APPOINTMENT_OF_AUDIT_COMMITTEE_MEMBER: { code: '080', name: '감사위원 선임' },
  APPOINTMENT_OF_OUTSIDE_DIRECTORS: {
    code: '090',
    name: '감사위원회 위원이 되는 사외이사 선임'
  },
  ETC: { code: '990', name: '기타' }
};

// 의안결의결과구분코드
export const AgendaResultType = {
  PASS: { code: '1', name: '가결' },
  REJECT: { code: '2', name: '부결' },
  INVALID: { code: '3', name: '무효' },
  REPORT: { code: '4', name: '보고' }
};

// 대행기관구분코드
export const AgentOrganizationType = {
  KSD: { code: '1', name: '한국예탁결제원' },
  KOOKMIN_BANK: { code: '2', name: '국민은행' },
  HANA_BANK: { code: '3', name: '하나은행' },
  DIRECT_MANAGEMENT: { code: '4', name: '직접관리' }
};

// 주주총회파일구분코드
export const AgmFileType = {
  RESOLUTION: { code: '1', name: '전자투표이사회 결의서' },
  ANNOUNCEMENT: { code: '2', name: '주주총회 공고문' },
  BUSINESS_REPORT: { code: '3', name: '사업보고서' },
  AUDIT_REPORT: { code: '4', name: '감사보고서' },
  IR: { code: '5', name: 'IR' }
};

// 주주총회처리코드
export const AgmProcess = {
  START_REGISTRATION: { code: '01', name: '주주총회 등록시작' },
  REGISTRATION_OF_AGENDA: { code: '02', name: '주주총회 의안 등록' },
  REGISTRATION_OF_SHAREHOLDERS: { code: '03', name: '주주총회 명부 등록' },
  REGISTRATION_OF_RESTRICT: { code: '04', name: '주주총회 의결권 제한 등록' },
  VOTER_DESIGNATION: { code: '05', name: '주주총회 투표권자 지정' },
  REGISTRATION_COMPLETE: { code: '06', name: '주주총회 등록 완료' },
  REGISTER_RESULTS: { code: '90', name: '주주총회 결과 등록' }
};

// 약관구분코드
export const AgmTermsType = {
  USE_OF_VOTING_SERVICE: { code: '1', name: '서비스 이용약관' },
  USE_PERSONAL_INFORMATION_FOR_SHAREHOLDER: {
    code: '2',
    name: '개인정보 수집 및 이용 동의 - 주주'
  },
  BUSINESS_CONSIGNMENT_OF_PERSONAL_INFORMATION_FOR_SHAREHOLDER: {
    code: '3',
    name: '개인정보 업무위탁 동의 - 주주'
  },
  UNIQUE_ID_INFORAMTION_TREATMENT: { code: '5', name: '고유식별 번호 처리' },
  USE_EVOTE_MANAGEMENT_SERVICE: {
    code: '6',
    name: '전자투표 관리 서비스 이용약관'
  },
  USE_PROXY_MANAGEMENT_SERVICE: {
    code: '7',
    name: '전자위임장권유관리서비스 이용약관'
  },
  USE_MARKETING: { code: '8', name: '마케팅 이용 동의서' },
  USE_PERSONAL_INFORMATION_FOR_ISSINC: {
    code: '12',
    name: '개인정보 수집 및 이용 동의 - 발행회사'
  },
  BUSINESS_CONSIGNMENT_OF_PERSONAL_INFORMATION_FOR_ISSINC: {
    code: '13',
    name: '개인정보 업무위탁 동의 - 발행회사'
  },
  COPYRIGHT_POLICY: { code: '20', name: '저작권 보호방침 - 홈페이지' },
  PRIVATE_POLICY: { code: '21', name: '개인정보 처리방침 - 홈페이지' }
};

// 주주총회구분코드
export const AgmType = {
  GENERAL: { code: '1', name: '정기' },
  TEMPORARY: { code: '2', name: '임시' }
};

// 인증 수단 구분코드
export const AuthenticationMethod = {
  CO_CERTIFICATE: { code: '1', name: '공동인증서' },
  PASS_APP: { code: '2', name: 'PASS' },
  KAKAO_PAY: { code: '3', name: '카카오페이' }
};

// 계약 파일 구분코드
export const ContractFileType = {
  VOTING_MANAGEMENT_CONSIGNMENT_CONTRACT: {
    code: '1',
    name: '전자투표관리 위탁계약서'
  },
  PROXY_RECOMMENDATION_CONSIGNMENT_CONTRACT: {
    code: '2',
    name: '전자위임장권유관리 위탁계약서'
  }
};

// 계약상태코드
export const ContractState = {
  CONTRACTED: { code: '1', name: '계약' },
  TERMINATION: { code: '2', name: '해지' },
  EXPIRATION: { code: '3', name: '만료' }
};

// DID사용구분코드
export const DIDUseType = {
  NOT_USE: { code: '0', name: '사용안함' },
  ALL_USE: { code: '1', name: '전체사용' },
  ONLY_APP: { code: '2', name: '앱만 사용' }
};

// 투표행사구분코드
export const ElectronicVoteExerciseType = {
  PARTICIPATE_VOTING: { code: '11', name: '전자투표 참여' },
  MODIFY_VOTING: { code: '12', name: '전자투표 수정' },
  AWARD_PROXY: { code: '21', name: '전자위임장 수여' },
  CANCEL_PROXY: { code: '23', name: '전자위임장 취소' }
};

// 발행회사파일구분코드
export const IssueIncorporatedFileType = {
  COPY_OF_CORPORATE_REGISTER: { code: '1', name: '법인등기부등본' },
  BUSINESS_LICENSE: { code: '2', name: '사업자등록증' }
};

// 권한구분코드
export const Role = {
  SYS_ADMIN: { code: '01', name: '시스템 관리자' },
  CONSULT_ADMIN: { code: '02', name: '상담관리자' },
  SECCO_ADMIN: { code: '03', name: '증권사 관리자' },
  ISSINC_ADMIN: { code: '04', name: '발행회사 관리자' },
  INDIVIUDAL_SHAREHOLDER: { code: '11', name: '개인주주' },
  CORP_SHAREHOLDER: { code: '12', name: '법인주주' },
  INSTITUTIONAL_INVESTOR: { code: '13', name: '기관투자자' },
  VIEW_ISSINC_ADMIN: { code: '98', name: '발행회사 조회용 로그인' }
};

// 주식시장구분코드
export const StockMarketType = {
  KOSPI: { code: '1', name: '유가증권' },
  KOSDAQ: { code: '2', name: '코스닥' },
  KONEX: { code: '3', name: '코넥스' },
  UNLISTED: { code: '4', name: '비상장' }
};

// 업로드진행코드
export const UploadProgress = {
  START: { code: 'S', name: '시작' },
  PARSE: { code: 'P', name: '파일파싱' },
  INTEGRITY: { code: 'I', name: '무결성 확인중' },
  UNION: { code: 'U', name: '주주명부 합산 업로드중' },
  DELETE: { code: 'D', name: '임시데이터 삭제중' },
  COMPLETE: { code: 'C', name: '완료' },
  ERROR: { code: 'E', name: '에러' }
};

// 업로드상태코드
export const UploadState = {
  NONE: { code: '0', name: '미 업로드' },
  MATCH: { code: '1', name: '일치' },
  UPLOADING: { code: '2', name: '업로드 중' },
  ERROR: { code: '9', name: '업로드 오류' }
};

// 의결권행사방법코드
export const VotingRightsExerciseMethod = {
  ELECTRONIC_VOTE: { code: '10', name: '전자투표' },
  SPOT_VOTE_TOTAL: { code: '20', name: '현장투표(합계)' },
  SPOT_VOTE: { code: '21', name: '현장투표' },
  WRITTEN_VOTE: { code: '22', name: '서면투표' },
  ELECTRONIC_PROXY: { code: '23', name: '전자위임장' },
  ELECTRONIC_BILL: {
    code: '24',
    name: '전자고지서',
    desc: '종이로 송달되던 고지서 및 통지서 대신 공인전자문서를 공인전자문서 중계자(카카오톡 등)를 통해 모바일 전자 고지서를 본인명의 스마트폰으로 송달하는 서비스 입니다.'
  }
};

// 대리인 유형 코드
export const ElecVoterClassCode = {
  INSTITUTIONAL_INVESTORS: { code: '1', name: '기관투자자(자산운영사)' },
  STANDING_PROXY: { code: '2', name: '상임대리인' },
  DR_CUSTODIAN: { code: '3', name: 'DR 보관기관' },
  CORPORATION: { code: '4', name: '기타법인' },
  PERSONAL: { code: '8', name: '개인' },
  ETC: { code: '9', name: '기타' }
};

// 의결권제한코드
export const VotingRightsLimitCode = {
  TREASURY_STOCK: {
    code: '01',
    name: '자사주(의결권 없는 주식수)',
    noVotingRights: true,
    votingRightsLimit: true
  },
  CROSS_HOLDING_OF_SHARES: {
    code: '02',
    name: '상호보유주식(의결권 없는 주식수)',
    noVotingRights: true,
    votingRightsLimit: false
  },
  INTERESTED_PERSON: {
    code: '03',
    name: '특별이해관계자',
    noVotingRights: false,
    votingRightsLimit: true
  },
  AUTITOR_OR_COMMITTEE_MEMBER: {
    code: '04',
    name: '감사/사외이사 아닌 감사위원 선임·해임(최대주주합산 3%)',
    noVotingRights: false,
    votingRightsLimit: true
  },
  OUTSIDE_DIRECTORS: {
    code: '05',
    name: '사외이사인 감사위원 선임·해임(단순합산 3%)',
    noVotingRights: false,
    votingRightsLimit: true
  },
  FSCMA: {
    code: '06',
    name: '자본시장법상 제한',
    noVotingRights: false,
    votingRightsLimit: true
  },
  FAIR_TRAND_ACT: {
    code: '07',
    name: '공정거래법상 제한',
    noVotingRights: false,
    votingRightsLimit: true
  },
  BANKING_ACT: {
    code: '08',
    name: '은행법상 제한',
    noVotingRights: false,
    votingRightsLimit: true
  },
  ACT_ON_CORPORATE_GOVERNANCE_OF_FINANCIAL_COMPANIES: {
    code: '09',
    name: '금융지배구조법상 제한',
    noVotingRights: false,
    votingRightsLimit: true
  },
  ETC: {
    code: '99',
    name: '기타',
    noVotingRights: true,
    votingRightsLimit: true
  }
};

// TODO 임시 파일 코드 {추후 변경 필요함}
export const TempFileType = {
  AGENDA_MORE_FIELS: '0'
};

export const getConstVotingRightsLimitByCode = (type, code) => {
  const target = Object.keys(type).find((key) => type[key].code === code);
  return type[target].votingRightsLimit;
};

export const getConstNoVotingRightsByCode = (type, code) => {
  const target = Object.keys(type).find((key) => type[key].code === code);
  return type[target].noVotingRights;
};

export const getConstNameByCode = (type, code) => {
  const target = Object.keys(type).find((key) => type[key].code === code);
  return type[target]?.name;
};

export const getConstCodeByName = (type, name) => {
  const target = Object.keys(type).find((key) => type[key].name === name);
  return type[target].code;
};

export const getAuthMethodStr = (methods) => {
  if (!!methods) {
    return methods
      .map((item) => getConstNameByCode(AuthenticationMethod, item))
      .join(', ');
  }
};

// 유저 타입 상수
export const UserType = {
  INDIVIDUAL: { name: 'individual', code: 0 },
  CORP: { name: 'corp', code: 1 },
  ISSINC: { name: 'issinc', code: 2 }
};

// 서비스 가입 순서 상수
export const SignupStep = {
  AuthenticationMethod: 1,
  Terms: 2,
  Info: 3,
  Confirm: 4
};

// 주총 상태 코드
export const AgmStateCd = {
  WRITING: '1',
  READY: '2',
  ING: '3',
  PLACE: '4',
  END: '5'
};

// 주총 상태 코드
export const AgmStateCode = {
  TEMPORARY: { code: '1', name: '개설 중' },
  MAKE: { code: '2', name: '개설 완료' },
  VOTING: { code: '3', name: '진행 중' },
  END: { code: '4', name: '전자주총 종료' },
  RESULT: { code: '5', name: '현장결과 등록' }
};

export const Tab_State_Const = {
  DONE: 1,
  ING: 2,
  WILL: 3
};

// 주주총회 참여 상태
export const ParticipateStatus = {
  NonParticipation: '0',
  SomeParticipation: '1',
  Participation: '2'
};

export const ElecBillApplyCode = {
  NOT_APPLY: {
    code: '01',
    name: '미 신청'
  },
  NOT_SEND: {
    code: '02',
    name: '미 발송'
  },
  RESERVE: {
    code: '03',
    name: '예약 발송'
  },
  COMPLETE: {
    code: '04',
    name: '발송 완료'
  }
};

export const ElecBillFileType = {
  ELEC_BILL: {
    code: '01',
    name: '주주총회 소집통지서'
  },
  ETC: {
    code: '02',
    name: '기타 참고 자료'
  }
};

export const ElecNoticeType = {
  ELEC_BILL: {
    code: '01',
    name: '전자고지서'
  }
};

export const SendPlatForm = {
  KAKAO: {
    code: '01',
    name: '카카오톡'
  }
};

export const SendTimeType = {
  IMMEDIATE: {
    text: '즉시 발송',
    value: '01'
  },
  RESERVE: {
    text: '예약 발송',
    value: '02'
  }
};

export const ReceiverType = {
  ALL: {
    name: '전체 주주',
    code: '1'
  },
  MIN_1_PERCENT: {
    name: '1% 이상의 지분을 소유한 주주',
    code: '9'
  }
};
